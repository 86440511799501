import React from 'react';

import VideoYoutube from '../../../../components/Video/VideoYoutube';
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';
import { youtube_parser } from '../../../../Utils/Utils';

let classNames = require('classnames');

const VideoSection = ({ videoDetails }) => {
  return (
    <div className={classNames('meetings_video', 'block_h3_content')}>
      <div className="wrapper_page">
        {videoDetails.content[0]?.title != 'undefined' && videoDetails.content[0]?.title && <TitleBlock
          headingTag="h3"
          title={videoDetails.content[0]?.title}
          color="color_dark_bleu"
        />}
      </div>
      <div className="wrapper_page_xs">
        <VideoYoutube
          cover={videoDetails.content[0]?.image?.uri?.url}
          alt={videoDetails.content[0]?.alt || ''}
          videoID={youtube_parser(videoDetails.content[0]?.link)}
          videoTitle={videoDetails.content[0]?.title}
          obj={{
            height: '390',
            width: '640',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
              controls: 0,
              disablekb: 1,
              rel: 0,
              showinfo: 0,
            },
          }}
        />
      </div>
    </div>
  );
};

export default VideoSection;
